<template>
  <section :class="`cardNew cardNew--${mainFeature} cardNew--${repeatColumns === 1 ? 'row' : 'column'}`" @click="setIdRecordToOpen">
    <div class="cardNew__firstSection">
      <div class="cardNew__contentCheck">
        <iconic class="cardNew__btnHistory" name="history" />
        <input class="cardNew__checkbox mycheck" type="checkbox" v-model="record.isSelected" @click.stop="" />
      </div>
      <DetailsRecordCard :record="record" />
    </div>
    <div class="cardNew__secSection">
      <MediaCardCarrousel :media="record.photos" :recordId="record._id" />
    </div>
    <div class="cardNew__threeSection" v-if="screenWidth >= 1160">
      <ModelMessage :message="record.youMessage" :isCard="true" v-if="screenWidth >= 1450" />
      <div v-else></div>
      <ActionButtons v-if="!record.isSelected && screenWidth > 1150" :onCancel="() => {}" :onSuccess="() => {}" />
    </div>
  </section>
</template>

<script>
export default {
  components: {
    MediaCardCarrousel: () => import("../mainRecords/MediaCardCarrousel.vue"),
    DetailsRecordCard: () => import("../mainRecords/DetailsRecordCard.vue"),
    ActionButtons: () => import("../mainRecords/ActionButtons.vue"),
    ModelMessage: () => import("../mainRecords/ModelMessage.vue"),
  },
  props: ["record"],
  computed: {
    features() {
      const dicDates = {
        priority: { name: "Prioridad", value: "high" },
        satelite: { name: "Satélite", value: "satelite" },
        gender: { name: "Masculino", value: "male" },
      };

      return Object.entries(dicDates).map(([key, { name, value }]) => ({
        key,
        name,
        value,
        selected: this.record[key] === value,
      }));
    },
    mainFeature() {
      if (this.age && this.age < 18) {
        return "younger";
      }
      if (this.record.isSelected) {
        return "selected";
      }
      return this.features.find(({ selected }) => selected)?.key || "";
    },
    age() {
      const birthDayDate = this.record.birthDayDate;
      if (!birthDayDate) {
        return "";
      }
      const birthDateObject = new Date(birthDayDate);

      if (isNaN(birthDateObject)) {
        return "Sin edad";
      }

      const currentDate = new Date();
      const timeDifferenceInMilliseconds = currentDate - birthDateObject;
      const ageInMilliseconds = new Date(timeDifferenceInMilliseconds);
      const age = Math.abs(ageInMilliseconds.getUTCFullYear() - 1970);

      return age;
    },
    screenWidth() {
      return this.$store.state.screen.width;
    },
    repeatColumns() {
      if (this.screenWidth <= 860) {
        return 1;
      } else if (this.screenWidth < 1160) {
        return 2;
      }
      return 3;
    },
  },
  methods: {
    setIdRecordToOpen() {
      this.$store.state.records.recordDetailId = this.record._id;
    },
  },
};
</script>

<style lang="scss">
.cardNew {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  width: 100%;
  min-height: 180px;
  gap: 15px;
  padding: 15px;
  background: #f9f8fd;
  box-shadow: 0px 0px 4px 0px #00000040;
  border-radius: 10px;
  transition: 0.3s ease-in-out;
  &:hover {
    box-shadow: 0px 0px 4px 0px #000000a8;
    cursor: pointer;
  }
  &--row {
    grid-template-columns: 1fr;
  }
  &--column {
    .cardNew__firstSection::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 1px;
      height: 90%;
      background-color: #cccccc;
    }
  }
  &--selected {
    background: #5b5957;
    transition: 0.3s ease-in-out;
    .detailRecord {
      transition: 0.3s ease-in-out;
      &__registerDate,
      &__iconContact,
      &__recordName {
        color: white;
      }
      &__recordCity {
        color: white;
        border-color: white;
      }
      &__detail {
        background: white;
        color: #5b5957;
      }
    }
    .messageContainer {
      background: #e9e9e9;
    }
  }
  &--younger {
    background: #fbd7d7;
  }
  &--priority {
    background: #2bbb6f2b;
  }
  &--satelite {
    background: #ff8c002b;
  }
  &--gender {
    background: #4d9bff2b;
  }
  &__firstSection {
    position: relative;
    @include Flex(row, flex-start);
    width: 100%;
    max-width: 100%;
    gap: 15px;
  }
  &__contentCheck {
    @include Flex(row);
  }
  &__btnHistory {
    position: absolute;
    top: 0;
    color: #5b595c;
    cursor: pointer;
  }
  &__checkbox {
    width: 18px;
    height: 18px;
    border: 1px solid #5b5957;
    border-radius: 4px;
    &:checked {
      background-color: $white;
      border-color: $chicago;
      color: $chicago;
    }
  }
  &__recordCity {
    @include Flex(row);
    height: 23px;
    padding: 0 10px;
    text-transform: capitalize;
    font-size: 14px;
    color: $primary-color;
    border: 1px solid $primary-color;
    border-radius: 17px;
  }
  &__detail {
    @include Flex(row);
    height: 18px;
    padding: 0 10px;
    font-size: 15px;
    color: white;
    background: #767676;
    border-radius: 50px;
  }
  &__secSection {
    @include Flex(row, flex-start);
    height: 100%;
    width: 100%;
    min-width: 200px;
    gap: 20px;
    box-sizing: border-box;
  }
  &__threeSection {
    @include Flex(row, space-between, flex-start);
    width: 100%;
    gap: 15px;
  }
}
</style>
